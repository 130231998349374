var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ActivatedRoute, Router } from '@angular/router';
import { OnDestroy, OnInit } from '@angular/core';
import { ModalController, ToastController } from 'ionic-angular';
import * as moment from 'moment';
import { CallListModel, ContactModel, DesktopPreferencesModel, TaskModel, PropertyModel, RescourNewsModel, CompModel, CompanyModel } from '@apto/models';
import { AnalyticsService, AppService, CallListsService, ContactGroupMembersService, ContactsService, FeaturesService, ListingsService, PreferencesService, TasksService, TypesService, CompsService, PropertiesService, OwnershipsService } from '@apto/services';
import { ContactTasksCard, RescourNewsCard } from '@apto/ionic-lib/cards';
import { AuthService, LogsService } from '@apto/ionic-lib/services';
import { AptoSimpleMapComponent, MarkerType, MarkerClassName } from '@apto/ng2-apto-map';
import { TOAST_DURATION } from '@apto/shared/constants';
import { MetricsService, AppcuesService } from '@apto/shared/services';
import { CallListModal, CallListModalMode, ContactDetailsModal, ContactDetailsModalMode, TasksModal, TasksModalMode } from '@apto/shared/modals';
import { ActivityHistoryModal, ActivityHistoryModalMode, LeaseModal, LeaseModalMode, PropertyDetailsModal, PropertyDetailsModalMode, SpaceModal, SpaceModalMode } from '@apto/pn/modals';
import { ContactLeasingPortfolioTabsCard, ContactLeasingPortfolioTabs, ManageOwnershipsModal } from '@apto/pn';
import { DashboardDataService, MappingCollection } from '@apto/pn/services';
import { AddActivity, AddFollowup, OpenTasks } from '@apto/pn/components';
import './dashboard.scss';
import { Location } from '@angular/common';
var VISIBLE_NEWS_COUNT = 3;
var VISIBLE_LEASE_COUNT = 3;
var ObjectState = /** @class */ /*@__PURE__*/ (function () {
    function ObjectState() {
        this.isInitializing = true;
        this.hasErrors = false;
        this.value = null;
    }
    return ObjectState;
}());
export { ObjectState };
var DashboardPage = /** @class */ /*@__PURE__*/ (function () {
    function DashboardPage(analyticsService, appcuesService, appService, authService, callListsService, compsService, contactGroupMembersService, contactsService, dashboardDataService, featuresService, listingsService, logsService, mappingCollection, metricsService, modalController, preferencesService, propertiesService, route, router, location, tasksService, toastController, typesService, ownershipsService) {
        var _this = this;
        this.analyticsService = analyticsService;
        this.appcuesService = appcuesService;
        this.appService = appService;
        this.authService = authService;
        this.callListsService = callListsService;
        this.compsService = compsService;
        this.contactGroupMembersService = contactGroupMembersService;
        this.contactsService = contactsService;
        this.dashboardDataService = dashboardDataService;
        this.featuresService = featuresService;
        this.listingsService = listingsService;
        this.logsService = logsService;
        this.mappingCollection = mappingCollection;
        this.metricsService = metricsService;
        this.modalController = modalController;
        this.preferencesService = preferencesService;
        this.propertiesService = propertiesService;
        this.route = route;
        this.router = router;
        this.location = location;
        this.tasksService = tasksService;
        this.toastController = toastController;
        this.typesService = typesService;
        this.ownershipsService = ownershipsService;
        this.callLists = [];
        this.childSpaceCounts = {};
        this.contact = new ContactModel();
        this.hoveredNews = null;
        this.hoveredProperty = null;
        this.isLoadingChildSpaceCounts = false;
        this.isLoadingDealActivity = false;
        this.isSavingContact = false;
        this.leasingEnabled = false;
        this.leases = [];
        this.news = [];
        this.newsEnabled = false;
        this.objectStates = {
            activities: new ObjectState(),
            callList: new ObjectState(),
            callLists: new ObjectState(),
            contact: new ObjectState(),
            leases: new ObjectState(),
            news: new ObjectState(),
            personInsights: new ObjectState(),
            properties: new ObjectState(),
            tasks: new ObjectState()
        };
        this.listsUpdating = false;
        this.properties = [];
        this.showEdit = true;
        this.shownLeases = [];
        this.shownNews = [];
        this.shownProperties = [];
        this.spacesEnabled = false;
        this.tasks = [];
        this.mapLoaded = false;
        this.subscriptions = [];
        this.contactTypes = [];
        this.currentLeasingCardTab = null;
        this.functionsToCallWhenMapIsReady = [];
        this.rootMetric = 'Dashboard Page';
        this.contactMetric = 'Contact Loaded';
        this.callListMetric = 'CallLists Loaded';
        this.contactChangedMetric = 'Contact Changed';
        this.getMemberByContactId = function (callList, contactId) {
            return callList.members.find(function (member) {
                return member.contactId === contactId;
            });
        };
        this.removeFromList = function (callList) {
            return __awaiter(_this, void 0, void 0, function () {
                var member;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.listsUpdating = true;
                            member = this.getMemberByContactId(callList, this.contactId);
                            if (!this.isContactDashboard && callList._id === this.callList._id) {
                                this.viewNextContact('Contact removed');
                            }
                            if (!member)
                                return [3 /*break*/, 5];
                            callList.members.splice(callList.members.indexOf(member), 1);
                            if (!(callList.members.length === 0))
                                return [3 /*break*/, 2];
                            return [4 /*yield*/, this.callListsService.delete([String(callList._id)])];
                        case 1:
                            _a.sent();
                            return [3 /*break*/, 3];
                        case 2:
                            this.callListsService.updateInCache(callList, callList._id);
                            _a.label = 3;
                        case 3: return [4 /*yield*/, this.contactGroupMembersService.delete([member._id])];
                        case 4:
                            _a.sent();
                            _a.label = 5;
                        case 5: return [4 /*yield*/, this.callListsService.fetch(undefined, true, true)];
                        case 6:
                            _a.sent();
                            this.listsUpdating = false;
                            return [2 /*return*/];
                    }
                });
            });
        };
        this.onListUpdate = function (lists) {
            return __awaiter(_this, void 0, void 0, function () {
                var _this = this;
                var curCL, contactIn;
                return __generator(this, function (_a) {
                    this.callLists = lists;
                    if (this.callLists) {
                        this.callLists.sort(function (a, b) {
                            return (a.name > b.name) ? 1 : -1;
                        });
                        this.markCallResult(this.tasks, this.contactId);
                    }
                    if (!this.isContactDashboard && this.callList) {
                        curCL = lists.find(function (cl) { return cl._id === _this.callList._id; });
                        if (curCL) {
                            this.callList = curCL;
                        }
                        contactIn = this.callList.members.find(function (mb) { return mb.contactId === _this.contactId; });
                        if (!contactIn) {
                            this.viewNextContact('Contact removed');
                        }
                    }
                    lists = this.callLists;
                    this.listsUpdating = false;
                    return [2 /*return*/];
                });
            });
        };
        this.onStartUpdateList = function () {
            _this.listsUpdating = true;
        };
    }
    DashboardPage.propertySort = function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    };
    DashboardPage.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var _a, e_1, err_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.metricsService.start(this.rootMetric);
                        this.analyticsService.logEvent('PN Page Load');
                        this.timeOnLoad = Date.now();
                        this.callListId = Number(this.route.snapshot.paramMap.get('callListId'));
                        if (this.isContactDashboard) {
                            this.metricsService.setRouteName('/call-lists/:contactId');
                        }
                        else {
                            this.metricsService.setRouteName('/call-lists/:callListId/:contactId');
                        }
                        this.setContactId(this.route.snapshot.paramMap.get('contactId') || undefined);
                        this.marketingStatus = this.route.snapshot.queryParamMap.get('marketingStatus') || undefined;
                        this.featureDetection(); // feature detection needs to happend right away
                        return [4 /*yield*/, this.loadContact()];
                    case 1:
                        _b.sent();
                        if (this.metricsService.userActionName === 'Open Dashboard') {
                            this.metricsService.stop(this.metricsService.userActionName);
                            this.metricsService.clearUserActionName(this.metricsService.userActionName);
                        }
                        this.typesService
                            .fetch()
                            .then(function (types) {
                            _this.contactTypes = types.contactTypes;
                        })
                            .catch(function (err) {
                            _this.logsService.exception('error while loading types', err);
                        });
                        _b.label = 2;
                    case 2:
                        _b.trys.push([2, 4, , 5]);
                        _a = this;
                        return [4 /*yield*/, this.callListsService.fetch()];
                    case 3:
                        _a.callLists = (_b.sent()).filter(function (el) { return el.type === CallListModel.TYPE.CONTACT_GROUP_LIST; });
                        this.objectStates.callLists.isInitializing = false;
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _b.sent();
                        this.logsService.exception('error while fetching callLists', e_1);
                        this.objectStates.callLists.hasErrors = true;
                        return [3 /*break*/, 5];
                    case 5:
                        if (this.callLists) {
                            this.callLists.sort(function (a, b) {
                                return (a.name > b.name) ? 1 : -1;
                            });
                        }
                        _b.label = 6;
                    case 6:
                        _b.trys.push([6, 8, , 9]);
                        return [4 /*yield*/, this.loadCallList()];
                    case 7:
                        _b.sent();
                        return [3 /*break*/, 9];
                    case 8:
                        err_1 = _b.sent();
                        this.logsService.exception('error while loading dashboard', err_1);
                        this.objectStates.callList.hasErrors = true;
                        return [3 /*break*/, 9];
                    case 9:
                        this.aptoSimpleMap.initMap();
                        this.aptoSimpleMap.setLogger(this.logsService);
                        this.createSubscriptions();
                        this.appcuesService.start();
                        this.metricsService.stop(this.rootMetric);
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardPage.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
    };
    Object.defineProperty(DashboardPage.prototype, "isContactDashboard", {
        get: function () {
            return !this.location.path().includes('/call-lists/');
        },
        enumerable: true,
        configurable: true
    });
    DashboardPage.prototype.hoverNews = function (event) {
        if (event.isHovered && event.news && event.news.id) {
            this.hoveredNews = event.news;
            this.aptoSimpleMap.getMarkerManager().hoverMarkerById("" + MarkerType.NEWS + this.hoveredNews.id);
        }
        else {
            if (this.hoveredNews) {
                this.aptoSimpleMap.getMarkerManager().removeHoverMarkerById("" + MarkerType.NEWS + this.hoveredNews.id);
            }
            this.hoveredNews = null;
        }
    };
    DashboardPage.prototype.hoverProperty = function (event) {
        if (event.isHovered && event.property) {
            this.hoveredProperty = event.property;
            // add hover on marker in map
            this.aptoSimpleMap.getMarkerManager().hoverMarkerById("" + MarkerType.PROPERTY + this.hoveredProperty._id);
        }
        else {
            if (this.hoveredProperty && this.hoveredProperty._id) {
                // remove hover on marker in map
                this.aptoSimpleMap.getMarkerManager().removeHoverMarkerById("" + MarkerType.PROPERTY + this.hoveredProperty._id);
            }
            this.hoveredProperty = null;
        }
    };
    DashboardPage.prototype.openEditContact = function (contact) {
        var contactDetailsModal = this.modalController.create(ContactDetailsModal, {
            mode: ContactDetailsModalMode.EDIT,
            selectedContact: contact,
            tasks: this.tasks,
            properties: this.properties,
            callLists: this.callLists,
            types: Object.keys(this.contactTypes)
        });
        contactDetailsModal.present({ updateUrl: false });
    };
    DashboardPage.prototype.editContact = function (contact) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isSavingContact = true;
                        return [4 /*yield*/, this.contactsService.upsert(contact)];
                    case 1:
                        _a.sent();
                        this.isSavingContact = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardPage.prototype.markTaskComplete = function (task) {
        this.showToast();
        // give time for green checkmark to show before removing.
        setTimeout(this.upsertTaskComplete.bind(this), 1000, task);
    };
    DashboardPage.prototype.upsertTaskComplete = function (task) {
        return __awaiter(this, void 0, void 0, function () {
            var err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        task.isComplete = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.tasksService.upsert(task)];
                    case 2:
                        _a.sent();
                        this.analyticsService.logEvent('Call List Task Completion');
                        TaskModel.sortActivities(this.tasks);
                        return [3 /*break*/, 4];
                    case 3:
                        err_2 = _a.sent();
                        task.isComplete = false;
                        return [3 /*break*/, 4];
                    case 4:
                        delete this.contactTasksCard.taskIsCompleting[task._id];
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardPage.prototype.showToast = function () {
        var toast = this.toastController.create({
            duration: TOAST_DURATION,
            message: 'Updating Task',
            showCloseButton: true
        });
        toast.present();
    };
    DashboardPage.prototype.saveFollowup = function (followupTask) {
        return __awaiter(this, void 0, void 0, function () {
            var err_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.tasksService.upsert(followupTask)];
                    case 1:
                        _a.sent();
                        this.viewNextContact('Logged activity');
                        this.analyticsService.logEvent('Call Logged Followup');
                        this.addFollowup.hide();
                        return [3 /*break*/, 3];
                    case 2:
                        err_3 = _a.sent();
                        this.addFollowup.hasErrors = true;
                        this.addFollowup.isSaving = false;
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DashboardPage.prototype.skipFollowup = function (event) {
        this.addFollowup.hide();
        if (!this.isContactDashboard) {
            this.viewNextContact('Skipped followup');
        }
    };
    Object.defineProperty(DashboardPage.prototype, "showContactEdit", {
        get: function () {
            return (this.showEdit &&
                !this.objectStates.tasks.isInitializing &&
                !this.objectStates.properties.isInitializing &&
                !this.objectStates.callList.isInitializing);
        },
        enumerable: true,
        configurable: true
    });
    DashboardPage.prototype.showAddActivity = function () {
        var activityHistoryModal = this.modalController.create(ActivityHistoryModal, {
            mode: ActivityHistoryModalMode.EDIT,
            relatedListing: this.relatedListing,
            selectedActivity: TaskModel.newLoggedCall(this.contact)
        });
        return activityHistoryModal.present({ updateUrl: false });
    };
    DashboardPage.prototype.showAddTask = function () {
        var tasksModal = this.modalController.create(TasksModal, {
            mode: TasksModalMode.EDIT,
            relatedListing: this.relatedListing,
            selectedTask: new TaskModel({
                contact: this.contact,
                contactId: this.contact._id
            })
        });
        return tasksModal.present({ updateUrl: false });
    };
    DashboardPage.prototype.showAddLease = function () {
        this.analyticsService.logEvent('Leasing Clicked Add Lease From Dashboard');
        var selectedRecord = {
            comp: new CompModel({
                tenantContact: this.contact,
                tenantContactId: this.contact._id,
                tenantCompany: this.contact.company,
                tenantCompanyId: this.contact.company && this.contact.company._id
            }),
            company: new CompanyModel(),
            property: new PropertyModel(),
            previousLeases: []
        };
        var leaseModal = this.modalController.create(LeaseModal, {
            mode: LeaseModalMode.EDIT,
            selectedRecord: selectedRecord
        });
        return leaseModal.present({ updateUrl: false });
    };
    DashboardPage.prototype.showAddSpace = function (event) {
        this.analyticsService.logEvent('Spaces Clicked Add Space From Dashboard');
        var spaceModal = this.modalController.create(SpaceModal, {
            mode: SpaceModalMode.EDIT,
            selectedRecord: {
                space: null,
                parentProperty: event.property
            }
        });
        return spaceModal.present({ updateUrl: false });
    };
    DashboardPage.prototype.taskReached = function (task) {
        var callReachedResults = Object.values(TaskModel.RESULT)
            .join(",")
            .replace("," + TaskModel.RESULT.NOT_REACHED, "")
            .replace("Inbound", "Received Call")
            .toUpperCase()
            .split(",");
        if (task.callResult) {
            return callReachedResults.includes(task.callResult.toUpperCase());
        }
        else {
            return true;
        }
    };
    DashboardPage.prototype.markCallResult = function (tasks, contactId) {
        var _this = this;
        var clData = this.callLists.filter(function (cl) {
            if (cl.configJson.daysSinceContact) {
                var foundMember = _this.getMemberByContactId(cl, contactId);
                if (foundMember) {
                    foundMember.callResult = false;
                    return true;
                }
                else {
                    return false;
                }
            }
            return false;
        });
        var datesLimits = clData.map(function (el) { return el.configJson.daysSinceContact; });
        var maxLimit = Math.max.apply(Math, datesLimits);
        var maxLimitDate = moment().subtract(maxLimit, 'd');
        var tasksInRange = tasks.find(function (task) {
            // We can't change completedDate for testing, so we replace it with dueDate if need to test.
            var taskDate = moment(task.description === 'testing' ? task.dueDate : task.completedDate);
            if (task.contactId === contactId &&
                task.isComplete &&
                _this.taskReached(task) &&
                taskDate.isSameOrAfter(maxLimitDate)) {
                return true;
            }
            else {
                return false;
            }
        });
        this.callLists.forEach(function (cl) {
            if (cl.configJson.daysSinceContact) {
                var foundMember = _this.getMemberByContactId(cl, contactId);
                if (foundMember) {
                    foundMember.callResult = !!tasksInRange;
                }
            }
        });
    };
    DashboardPage.prototype.saveTask = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var err_4, incompleteTasks;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!event.task) {
                            return [2 /*return*/];
                        }
                        this.addFollowUpChecked = event ? event.addFollowUp : false;
                        this.reviewTasksChecked = event ? event.openTasks : false;
                        return [4 /*yield*/, this.savePreferences()];
                    case 1:
                        _a.sent();
                        this.isLoggingActivity = true;
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.tasksService.upsert(event.task)];
                    case 3:
                        _a.sent();
                        if (event.task.isComplete && !this.isContactDashboard && !this.reviewTasksChecked && !this.addFollowUpChecked) {
                            this.viewNextContact('Logged activity');
                        }
                        this.trackLoggedCall(event.task);
                        return [3 /*break*/, 5];
                    case 4:
                        err_4 = _a.sent();
                        return [3 /*break*/, 5];
                    case 5:
                        this.isLoggingActivity = false;
                        if (!this.reviewTasksChecked && this.addFollowUpChecked) {
                            this.addFollowUpForm(event);
                        }
                        else if (this.reviewTasksChecked && !this.addFollowUpChecked || this.reviewTasksChecked && this.addFollowUpChecked) {
                            incompleteTasks = this.tasks.filter(function (task) {
                                return task.isComplete === false;
                            });
                            if (incompleteTasks.length > 0) {
                                this.openTasks.show();
                            }
                            else {
                                this.addFollowUpForm(event);
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardPage.prototype.addFollowUpForm = function (event) {
        this.openTasks.hide();
        if (this.addFollowUpChecked) {
            this.addFollowup.show();
        }
        else {
            this.viewNextContact('Logged activity');
        }
    };
    DashboardPage.prototype.showActivityForm = function (event) {
        this.openTasks.hide();
        this.addFollowup.hide();
        this.addActivity.show(event.listing, event.marketingStatus);
    };
    DashboardPage.prototype.saveActivity = function (activity, unsetLoadingFlag) {
        if (unsetLoadingFlag === void 0) {
            unsetLoadingFlag = true;
        }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoadingDealActivity = true;
                        return [4 /*yield*/, this.tasksService.upsert(activity)];
                    case 1:
                        _a.sent();
                        if (unsetLoadingFlag) {
                            this.isLoadingDealActivity = false;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardPage.prototype.saveActivityAndNext = function (activity) {
        return __awaiter(this, void 0, void 0, function () {
            var err_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.saveActivity(activity, false)];
                    case 1:
                        _a.sent();
                        this.viewNextContact('Logged activity');
                        return [3 /*break*/, 3];
                    case 2:
                        err_5 = _a.sent();
                        this.isLoadingDealActivity = false;
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DashboardPage.prototype.cancelDealAdvancement = function () {
        this.addActivity.hide();
    };
    DashboardPage.prototype.changeLeasingCardTab = function (tab) {
        if (this.currentLeasingCardTab === tab) {
            return;
        }
        if (tab === ContactLeasingPortfolioTabs.PORTFOLIO) {
            this.analyticsService.logEvent('Leasing Clicked Owner Tab From Dashboard');
        }
        else if (tab === ContactLeasingPortfolioTabs.LEASES) {
            this.analyticsService.logEvent('Leasing Clicked Lease Tab From Dashboard');
        }
        this.currentLeasingCardTab = tab;
        if (tab === ContactLeasingPortfolioTabs.PORTFOLIO) {
            this.addPropertiesToMap();
        }
        else {
            this.addLeasingPropertiesToMap();
        }
    };
    DashboardPage.prototype.skipContact = function (contact) {
        return __awaiter(this, void 0, void 0, function () {
            var activity;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoggingActivity = true;
                        activity = TaskModel.newSkippedActivity(contact);
                        this.tasks.push(activity);
                        TaskModel.sortActivities(this.tasks);
                        return [4 /*yield*/, this.tasksService.upsert(activity)];
                    case 1:
                        _a.sent();
                        this.isLoggingActivity = false;
                        this.getMemberByContactId(this.callList, contact._id).callResult = true;
                        this.viewNextContact('Contact skipped');
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardPage.prototype.viewAllActivities = function (event) {
        var activityHistoryModal = this.modalController.create(ActivityHistoryModal, {
            activities: this.tasks,
            mode: ActivityHistoryModalMode.LIST
        });
        activityHistoryModal.present({ updateUrl: false });
    };
    DashboardPage.prototype.viewActivity = function (activity) {
        var activityHistoryModal = this.modalController.create(ActivityHistoryModal, {
            activities: this.tasks,
            mode: ActivityHistoryModalMode.VIEW,
            selectedActivity: activity
        });
        activityHistoryModal.present({ updateUrl: false });
    };
    DashboardPage.prototype.viewAllOpenTasks = function (event) {
        var tasksModal = this.modalController.create(TasksModal, {
            mode: TasksModalMode.LIST,
            tasks: this.tasks,
            filter: 'open'
        });
        tasksModal.present({ updateUrl: false });
    };
    DashboardPage.prototype.viewCallList = function (callList) {
        var _this = this;
        var modalClass = callList.isTaskList ? TasksModal : CallListModal;
        var mode = callList.isTaskList ? TasksModalMode.LIST : CallListModalMode.VIEW;
        var modal = this.modalController.create(modalClass, {
            callList: callList,
            mode: mode,
            tasks: callList.tasks,
            title: callList.name
        });
        if (!callList.isTaskList) {
            modal.onDidDismiss(function (data) {
                if (data && data.selectedContact && data.selectedContact._id) {
                    _this.router.navigate(['/call-lists', callList._id, data.selectedContact._id]);
                }
            });
        }
        modal.present({ updateUrl: false });
    };
    DashboardPage.prototype.viewTask = function (task) {
        var tasksModal = this.modalController.create(TasksModal, {
            mode: TasksModalMode.VIEW,
            tasks: this.tasks,
            selectedTask: task,
            filter: 'open'
        });
        tasksModal.present({ updateUrl: false });
    };
    DashboardPage.prototype.closeCallList = function () {
        if (this.isContactDashboard) {
            this.router.navigate(['/contacts']);
        }
        else {
            this.router.navigate(['/call-lists']);
        }
    };
    DashboardPage.prototype.viewAllPortfolio = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var propertyDetailsModal, _a, _b, _c, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _b = (_a = this.modalController).create;
                        _c = [PropertyDetailsModal];
                        _d = {
                            contactId: this.contactId,
                            properties: event.properties,
                            mode: PropertyDetailsModalMode.LIST
                        };
                        return [4 /*yield*/, this.getPropertyTypes()];
                    case 1:
                        propertyDetailsModal = _b.apply(_a, _c.concat([(_d.types = _e.sent(),
                                _d), {
                                cssClass: 'large-modal'
                            }]));
                        propertyDetailsModal.present({ updateUrl: false });
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(DashboardPage.prototype, "readySwitch", {
        get: function () {
            return (!this.objectStates.leases.isInitializing &&
                !this.objectStates.tasks.isInitializing &&
                !this.objectStates.properties.isInitializing);
        },
        enumerable: true,
        configurable: true
    });
    DashboardPage.prototype.viewNextContact = function (action) {
        if (action === void 0) {
            action = 'Skipped contact';
        }
        if (this.isContactDashboard || !this.readySwitch) {
            return;
        }
        this.metricsService.setUserActionName(this.contactChangedMetric);
        this.metricsService.start(this.contactChangedMetric);
        this.logViewingTime(action);
        var nextContact = this.callList.nextContact(this.contactId, this.marketingStatus);
        var prevContact = this.callList.previousContact(this.contactId, this.marketingStatus);
        if (nextContact) {
            this.router.navigate(['/call-lists', this.callList._id, nextContact.contactId]);
        }
        else if (prevContact) {
            this.router.navigate(['/call-lists', this.callList._id, prevContact.contactId]);
        }
        else {
            this.router.navigate(['/call-lists']);
        }
    };
    DashboardPage.prototype.viewPreviousContact = function () {
        if (this.isContactDashboard || !this.readySwitch) {
            return;
        }
        this.metricsService.setUserActionName(this.contactChangedMetric);
        this.metricsService.start(this.contactChangedMetric);
        this.router.navigate([
            '/call-lists',
            this.callList._id,
            this.callList.previousContact(this.contactId, this.marketingStatus).contactId
        ]);
    };
    DashboardPage.prototype.viewNews = function (news) {
        var link = document.createElement('a');
        link.target = '_blank';
        link.href = news.sourceUrl;
        link.click();
        this.analyticsService.logEvent('Rescour News View Article From Dashboard');
        // flag article as viewed
        this.rescourNewsCard.viewedNewsHandler(news);
    };
    DashboardPage.prototype.viewProperty = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var propertyDetailsModal, _a, _b, _c, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _b = (_a = this.modalController).create;
                        _c = [PropertyDetailsModal];
                        _d = {
                            selectedProperty: event.property,
                            mode: PropertyDetailsModalMode.VIEW
                        };
                        return [4 /*yield*/, this.getPropertyTypes()];
                    case 1:
                        propertyDetailsModal = _b.apply(_a, _c.concat([(_d.types = _e.sent(),
                                _d), {
                                cssClass: 'large-modal'
                            }]));
                        propertyDetailsModal.present({ updateUrl: false });
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardPage.prototype.viewSpaces = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var propertyDetailsModal, _a, _b, _c, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        this.analyticsService.logEvent('Spaces Clicked View Spaces From Dashboard');
                        _b = (_a = this.modalController).create;
                        _c = [PropertyDetailsModal];
                        _d = {
                            selectedProperty: event.property,
                            mode: PropertyDetailsModalMode.VIEW
                        };
                        return [4 /*yield*/, this.getPropertyTypes()];
                    case 1:
                        propertyDetailsModal = _b.apply(_a, _c.concat([(_d.types = _e.sent(),
                                _d.spacesTabIndex = 1,
                                _d), {
                                cssClass: 'large-modal'
                            }]));
                        propertyDetailsModal.present({ updateUrl: false });
                        return [2 /*return*/];
                }
            });
        });
    };
    // handler for map marker click
    DashboardPage.prototype.onMarkerClick = function (marker) {
        if (marker.type === MarkerType.PROPERTY) {
            // if viewing the lease tab open the lease instead of the property
            if (this.contactLeasingPortfolioTabsCard &&
                this.contactLeasingPortfolioTabsCard.currentTab === ContactLeasingPortfolioTabs.LEASES) {
                var leaseShape = this.leases.find(function (l) {
                    return l.property && l.property._id === marker.data._id;
                });
                if (leaseShape) {
                    this.viewLease(leaseShape);
                    return;
                }
            }
            this.viewProperty({
                property: new PropertyModel(marker.data)
            });
        }
        else if (marker.type === MarkerType.NEWS) {
            this.analyticsService.logEvent('Rescour News Clicked News Marker From Dashboard');
            this.viewNews(marker.data);
        }
    };
    // handler for map marker mouse out
    DashboardPage.prototype.onMarkerMouseOut = function (marker) {
        if (marker.type === MarkerType.PROPERTY) {
            this.hoverProperty({
                isHovered: false
            });
        }
        if (marker.type === MarkerType.NEWS) {
            this.hoverNews({
                isHovered: false
            });
        }
    };
    // handler for map marker hover
    DashboardPage.prototype.onMarkerHover = function (marker) {
        if (marker.type === MarkerType.PROPERTY) {
            this.hoverProperty({
                isHovered: true,
                property: new PropertyModel(marker.data)
            });
        }
        else if (marker.type === MarkerType.NEWS) {
            this.hoverNews({
                isHovered: true,
                news: new RescourNewsModel(marker.data)
            });
        }
    };
    DashboardPage.prototype.toggleVisibleNews = function () {
        if (this.shownNews.length === this.news.length) {
            this.shownNews = this.news.slice(0, VISIBLE_NEWS_COUNT);
        }
        else {
            this.shownNews = this.news;
        }
        this.analyticsService.logEvent('Rescour News Clicked View More From Dashboard');
    };
    DashboardPage.prototype.viewLease = function (event) {
        var leaseModal = this.modalController.create(LeaseModal, {
            selectedRecord: event,
            mode: LeaseModalMode.VIEW
        }, {
            cssClass: 'large-modal'
        });
        leaseModal.present({ updateUrl: false });
        this.analyticsService.logEvent('Leasing Clicked View Lease From Dashboard');
    };
    DashboardPage.prototype.toggleVisibleLeases = function () {
        if (this.shownLeases.length === this.leases.length) {
            this.analyticsService.logEvent('Leasing Clicked View Less From Dashboard');
            this.shownLeases = this.leases.slice(0, VISIBLE_LEASE_COUNT);
        }
        else {
            this.analyticsService.logEvent('Leasing Clicked View All From Dashboard');
            this.shownLeases = this.leases;
        }
    };
    DashboardPage.prototype.onMapReady = function (map) {
        this.mapLoaded = true;
        this.functionsToCallWhenMapIsReady.forEach(function (func) { return func(); });
    };
    DashboardPage.prototype.getLeaseMarker = function (comp) {
        if (comp && comp.leaseExpirationDate) {
            var currentDate = moment();
            var expirationDate = moment(comp.leaseExpirationDate);
            if (expirationDate < currentDate.add(1, 'years')) {
                return MarkerClassName.LEASE_DANGER;
            }
            if (expirationDate < currentDate.add(1, 'years')) {
                return MarkerClassName.LEASE_WARNING;
            }
        }
        return null;
    };
    DashboardPage.prototype.loadTasks = function (force) {
        return __awaiter(this, void 0, void 0, function () {
            var tasks, ex_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        this.objectStates.tasks.isInitializing = true;
                        this.objectStates.activities.isInitializing = true;
                        return [4 /*yield*/, this.dashboardDataService.getTasks(this.contactId, force)];
                    case 1:
                        tasks = _a.sent();
                        TaskModel.sortActivities(tasks);
                        this.tasks = tasks;
                        return [3 /*break*/, 4];
                    case 2:
                        ex_1 = _a.sent();
                        this.logsService.exception('error loading tasks', ex_1);
                        this.objectStates.tasks.hasErrors = true;
                        return [3 /*break*/, 4];
                    case 3:
                        this.objectStates.activities.isInitializing = false;
                        this.objectStates.tasks.isInitializing = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DashboardPage.prototype.loadLeases = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var _a, ex_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 3, 4, 5]);
                        this.objectStates.leases.isInitializing = true;
                        _a = this;
                        return [4 /*yield*/, this.dashboardDataService.getLeasesForContact(this.contact)];
                    case 1:
                        _a.leases = (_b.sent())
                            .filter(function (l) { return l.comp.tenantContactId === _this.contactId; });
                        return [4 /*yield*/, this.getLatLngForProperties(this.leases.map(function (lease) { return lease.property; }).filter(function (prop) { return prop !== undefined; }))];
                    case 2:
                        _b.sent();
                        this.shownLeases = this.leases.slice(0, VISIBLE_LEASE_COUNT);
                        if (this.contactLeasingPortfolioTabsCard &&
                            this.contactLeasingPortfolioTabsCard.currentTab === ContactLeasingPortfolioTabs.LEASES) {
                            this.addLeasingPropertiesToMap();
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        ex_2 = _b.sent();
                        this.logsService.exception('error loading leases', ex_2);
                        this.objectStates.leases.hasErrors = true;
                        return [3 /*break*/, 5];
                    case 4:
                        this.objectStates.leases.isInitializing = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    DashboardPage.prototype.loadProperties = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, ex_3;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 6, 7, 8]);
                        this.objectStates.properties.isInitializing = true;
                        _a = this;
                        return [4 /*yield*/, this.dashboardDataService.getPropertiesByOwner(this.contactId)];
                    case 1:
                        _a.properties = _c.sent();
                        this.properties.sort(DashboardPage.propertySort);
                        return [4 /*yield*/, this.attachOwnerships(this.properties)];
                    case 2:
                        _c.sent();
                        if (!(this.spacesEnabled && this.properties && this.properties.length))
                            return [3 /*break*/, 4];
                        this.isLoadingChildSpaceCounts = true;
                        _b = this;
                        return [4 /*yield*/, this.dashboardDataService.getChildSpaceCountsByParentPropertyIds(this.properties.map(function (property) { return property._id; }))];
                    case 3:
                        _b.childSpaceCounts = _c.sent();
                        this.isLoadingChildSpaceCounts = false;
                        _c.label = 4;
                    case 4:
                        // get geocodes for properties that don't have lat/lng
                        return [4 /*yield*/, this.getLatLngForProperties(this.properties)];
                    case 5:
                        // get geocodes for properties that don't have lat/lng
                        _c.sent();
                        // News needs the geocodes so don't set properties until the getLatLngForProperties request is done.
                        this.shownProperties = this.properties.slice(0, 10);
                        if (!this.leasingEnabled || (this.leasingEnabled &&
                            this.contactLeasingPortfolioTabsCard.currentTab !== ContactLeasingPortfolioTabs.LEASES)) {
                            this.addPropertiesToMap();
                        }
                        return [3 /*break*/, 8];
                    case 6:
                        ex_3 = _c.sent();
                        this.logsService.exception('error loading properties', ex_3);
                        this.objectStates.properties.hasErrors = true;
                        return [3 /*break*/, 8];
                    case 7:
                        this.objectStates.properties.isInitializing = false;
                        this.isLoadingChildSpaceCounts = false;
                        return [7 /*endfinally*/];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    DashboardPage.prototype.attachOwnerships = function (properties) {
        return __awaiter(this, void 0, void 0, function () {
            var ownerships;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.ownershipsService.byContact(this.contactId)];
                    case 1:
                        ownerships = _a.sent();
                        //delete company data, impact from the default company contact
                        ownerships.forEach(function (ow) {
                            delete ow.companyId;
                            delete ow.company;
                        });
                        properties.forEach(function (property) {
                            property.ownership = ownerships.find(function (ow) { return ow.propertyId === property._id; });
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardPage.prototype.loadNews = function () {
        return __awaiter(this, void 0, void 0, function () {
            var news, err_6;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        this.objectStates.news.isInitializing = true;
                        return [4 /*yield*/, this.dashboardDataService.getRescourNews(this.properties)];
                    case 1:
                        news = _a.sent();
                        this.news = news;
                        this.addNewsToMap();
                        this.shownNews = this.news.slice(0, VISIBLE_NEWS_COUNT);
                        if (this.news.length) {
                            this.analyticsService.logEvent('Rescour News Loaded From Dashboard');
                        }
                        return [3 /*break*/, 4];
                    case 2:
                        err_6 = _a.sent();
                        this.logsService.exception('Dashboard news error', err_6);
                        this.objectStates.news.hasErrors = true;
                        return [3 /*break*/, 4];
                    case 3:
                        this.objectStates.news.isInitializing = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DashboardPage.prototype.loadContactDependantPageData = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadProperties()];
                    case 1:
                        _a.sent();
                        this.loadLeases();
                        this.loadNews();
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardPage.prototype.setContactDependantPageDataAsInitializing = function () {
        this.objectStates.news.isInitializing = true;
        this.objectStates.properties.isInitializing = true;
        this.objectStates.leases.isInitializing = true;
    };
    DashboardPage.prototype.loadContactIdDependantPageData = function () {
        this.loadTasks();
    };
    DashboardPage.prototype.setContact = function (contact) {
        if (this.contact !== contact) {
            this.contact = contact;
            this.loadContactDependantPageData();
        }
    };
    DashboardPage.prototype.setContactId = function (contactId) {
        if (contactId && this.contactId !== contactId) {
            this.contactId = contactId;
            this.loadContactIdDependantPageData();
            return true;
        }
        return false;
    };
    DashboardPage.prototype.createSubscriptions = function () {
        var _this = this;
        this.subscriptions.concat([
            this.route.paramMap.subscribe(function (paramMap) {
                return __awaiter(_this, void 0, void 0, function () {
                    var changedContactId;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                changedContactId = this.setContactId(paramMap.get('contactId'));
                                if (!changedContactId)
                                    return [3 /*break*/, 2];
                                return [4 /*yield*/, this.loadContact()];
                            case 1:
                                _a.sent();
                                if (this.metricsService.userActionName) {
                                    this.metricsService.stop(this.metricsService.userActionName);
                                    this.metricsService.clearUserActionName(this.metricsService.userActionName);
                                }
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                });
            }),
            this.contactsService.onDeleted.subscribe(function (data) {
                if (data.payload.includes(_this.contact._id)) {
                    _this.callLists.forEach(function (cl) {
                        cl.members = cl.members.filter(function (member) { return member.contactId !== _this.contact._id; });
                    });
                    _this.closeCallList();
                }
            }),
            this.callListsService.onDeleted.subscribe(function (data) {
                if (_this.callList) {
                    var index = data.payload.indexOf(_this.callList._id.toString());
                    if (index >= 0) {
                        _this.router.navigate(['/call-lists']);
                    }
                }
            }),
            this.propertiesService.onUpserted.subscribe(function (data) {
                _this.loadProperties();
            }),
            this.propertiesService.onDeleted.subscribe(function (data) {
                _this.loadProperties();
            }),
            this.ownershipsService.onDeleted.subscribe(function (data) {
                return __awaiter(_this, void 0, void 0, function () {
                    var _this = this;
                    return __generator(this, function (_a) {
                        this.ownershipsService.byContact(this.contactId).then(function (ownerships) {
                            var propIds = ownerships
                                .filter(function (el) { return !!el.propertyId; })
                                .map(function (el) { return el.propertyId; });
                            _this.shownProperties = _this.shownProperties.filter(function (prop) {
                                return propIds.includes(prop._id);
                            });
                            _this.properties = _this.properties.filter(function (prop) { return propIds.includes(prop._id); });
                        });
                        return [2 /*return*/];
                    });
                });
            }),
            this.tasksService.onUpserted.subscribe(function (data) {
                return __awaiter(_this, void 0, void 0, function () {
                    var task, unchangedTasks, tasks;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                task = data.payload;
                                this.tasksService.updateInCache(task, task._id);
                                unchangedTasks = this.tasks.filter(function (el) { return el._id !== task._id; });
                                if (!(task.contactId === this.contactId))
                                    return [3 /*break*/, 1];
                                this.tasks = unchangedTasks.concat([task]);
                                return [3 /*break*/, 3];
                            case 1:
                                this.tasks = unchangedTasks.slice();
                                return [4 /*yield*/, this.dashboardDataService.getTasks(task.contactId)];
                            case 2:
                                tasks = _a.sent();
                                this.markCallResult(tasks, task.contactId);
                                _a.label = 3;
                            case 3:
                                TaskModel.sortActivities(this.tasks);
                                this.markCallResult(this.tasks, this.contactId);
                                if (!data.isNew) {
                                    return [2 /*return*/];
                                }
                                if (!task.isComplete || !task.marketingStatus || !task.contact) {
                                    return [2 /*return*/];
                                }
                                this.callLists = this.callLists.slice();
                                this.addActivity.hide();
                                return [2 /*return*/];
                        }
                    });
                });
            }),
            this.tasksService.onDeleted.subscribe(function (data) {
                _this.tasks = _this.tasks.filter(function (task) { return data.payload.indexOf(task._id) === -1; });
            }),
            this.compsService.onUpserted.subscribe(function (data) {
                _this.dashboardDataService.clearLeaseData(_this.contact._id);
                _this.loadLeases();
            }),
            this.compsService.onDeleted.subscribe(function (data) {
                _this.dashboardDataService.clearLeaseData(_this.contact._id);
                _this.loadLeases();
            })
        ]);
    };
    DashboardPage.prototype.loadContact = function () {
        return __awaiter(this, void 0, void 0, function () {
            var contact, ex_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.metricsService.start(this.rootMetric + ":" + this.contactMetric);
                        this.objectStates.contact.isInitializing = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        if (!this.contactId) {
                            // if we don't have a contact id yet, wait for loadCallList to call this method since it will
                            // assign a contactId if one wasn't specified.
                            return [2 /*return*/];
                        }
                        // since we are about to load a contact and get new page data when done, set the loading state
                        // for all the components that will be getting new data after the contact is loaded
                        this.setContactDependantPageDataAsInitializing();
                        return [4 /*yield*/, this.dashboardDataService.getContact(this.contactId, false)];
                    case 2:
                        contact = _a.sent();
                        this.setContact(contact);
                        // load full contact after we have contact
                        this.loadFullContact();
                        return [3 /*break*/, 5];
                    case 3:
                        ex_4 = _a.sent();
                        this.logsService.exception('error loading contact', ex_4);
                        this.objectStates.contact.hasErrors = true;
                        return [3 /*break*/, 5];
                    case 4:
                        this.objectStates.contact.isInitializing = false;
                        this.metricsService.stop(this.rootMetric + ":" + this.contactMetric);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    DashboardPage.prototype.loadFullContact = function () {
        return __awaiter(this, void 0, void 0, function () {
            var model, ex_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.objectStates.personInsights.isInitializing = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.dashboardDataService.getFullContactInsights(this.contact)];
                    case 2:
                        model = _a.sent();
                        if (model) {
                            this.analyticsService.logEvent('Showing FullContact Result');
                            this.personInsights = model;
                        }
                        else {
                            this.personInsights = null;
                            this.analyticsService.logEvent('Not Showing FullContact Result');
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        ex_5 = _a.sent();
                        this.logsService.exception('error loading full contact results', ex_5);
                        this.objectStates.personInsights.hasErrors = true;
                        return [3 /*break*/, 5];
                    case 4:
                        this.objectStates.personInsights.isInitializing = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    DashboardPage.prototype.showAddProperty = function () {
        var _this = this;
        var propertyModal = this.modalController.create(ManageOwnershipsModal, {
            selectedRecord: this.contact
        });
        propertyModal.onDidDismiss(function (result) {
            if (result) {
                _this.properties = result.properties;
                _this.properties.sort(DashboardPage.propertySort);
                _this.attachOwnerships(_this.properties);
                _this.shownProperties = _this.properties.slice(0, 10);
            }
        });
        propertyModal.present({ updateUrl: false });
    };
    DashboardPage.prototype.featureDetection = function () {
        var _this = this;
        this.subscriptions.push(this.authService.onSetUser.subscribe(function (user) {
            if (user) {
                _this.leasingEnabled = _this.featuresService.isFeatureEnabled(_this.featuresService.FEATURES.LEASING);
                _this.dashboardDataService.leasingEnabled = _this.leasingEnabled;
                _this.newsEnabled = _this.featuresService.isFeatureEnabled(_this.featuresService.FEATURES.NEWS);
                _this.dashboardDataService.newsEnabled = _this.newsEnabled;
                _this.spacesEnabled = _this.featuresService.isFeatureEnabled(_this.featuresService.FEATURES.SPACES);
                _this.dashboardDataService.spacesEnabled = _this.spacesEnabled;
            }
        }, this.logsService.error));
    };
    DashboardPage.prototype.addNewsToMap = function () {
        if (!this.mapLoaded) {
            this.functionsToCallWhenMapIsReady.push(this.addNewsToMap.bind(this));
            return;
        }
        var newsMarkers = [];
        this.news.forEach(function (n) {
            if (n.location && n.location.length) {
                newsMarkers.push({
                    id: n.id,
                    latitude: n.location && n.location[1],
                    longitude: n.location && n.location[0],
                    iconClass: MarkerClassName.NEWS,
                    data: n,
                    address: null
                });
            }
        });
        this.aptoSimpleMap.getMarkerManager().deleteMarkersByType(MarkerType.NEWS);
        this.aptoSimpleMap.addMarkers(newsMarkers, MarkerType.NEWS, true);
    };
    DashboardPage.prototype.getLatLngForProperties = function (properties) {
        return __awaiter(this, void 0, void 0, function () {
            var ids, idParams, res_1, ex_6;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        ids = properties.filter(function (property) { return property !== undefined; }).map(function (property) {
                            if (!property.location || !property.location.lat || !property.location.lng) {
                                return property._id;
                            }
                        });
                        if (!ids.length)
                            return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        idParams = ids.length === 1
                            ? "sf_id=" + ids[0]
                            : ids.map(function (el) { return "sf_id__in=" + el; }).join('&');
                        return [4 /*yield*/, this.mappingCollection.fetch(idParams + "&columns=latitude,longitude,sf_id")];
                    case 2:
                        res_1 = _a.sent();
                        // assign location
                        properties.forEach(function (property) {
                            for (var _i = 0, _a = res_1.properties; _i < _a.length; _i++) {
                                var propResult = _a[_i];
                                if (propResult._id === property._id) {
                                    property.location = propResult.location;
                                    break;
                                }
                            }
                        });
                        return [3 /*break*/, 4];
                    case 3:
                        ex_6 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, null];
                }
            });
        });
    };
    DashboardPage.prototype.addPropertiesToMap = function () {
        if (!this.mapLoaded) {
            this.functionsToCallWhenMapIsReady.push(this.addPropertiesToMap.bind(this));
            return;
        }
        var markerOptions = [];
        this.shownProperties.forEach(function (p) {
            markerOptions.push({
                id: p._id,
                latitude: p.location && p.location.lat,
                longitude: p.location && p.location.lng,
                iconClass: null,
                data: p,
                address: p.address ? p.address.toString() : null
            });
        });
        this.aptoSimpleMap.getMarkerManager().deleteMarkersByType(MarkerType.PROPERTY);
        this.aptoSimpleMap.addMarkers(markerOptions, MarkerType.PROPERTY, true);
    };
    DashboardPage.prototype.addLeasingPropertiesToMap = function () {
        var _this = this;
        if (!this.mapLoaded) {
            this.functionsToCallWhenMapIsReady.push(this.addLeasingPropertiesToMap.bind(this));
            return;
        }
        var markerOptions = [];
        this.leases.forEach(function (lease) {
            if (lease.property) {
                markerOptions.push({
                    id: lease.property._id,
                    latitude: lease.property.location && lease.property.location.lat,
                    longitude: lease.property.location && lease.property.location.lng,
                    iconClass: _this.getLeaseMarker(lease.comp),
                    data: lease.property,
                    address: lease.property.address ? lease.property.address.toString() : null
                });
            }
        });
        this.aptoSimpleMap.getMarkerManager().deleteMarkersByType(MarkerType.PROPERTY);
        this.aptoSimpleMap.addMarkers(markerOptions, MarkerType.PROPERTY, true);
    };
    DashboardPage.prototype.loadCallList = function () {
        return __awaiter(this, void 0, void 0, function () {
            var callList, callList, sortedCallList, groupMember, changedContactId, ex_7;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.metricsService.start(this.rootMetric + ":" + this.callListMetric);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 17, 18, 20]);
                        if (this.isContactDashboard) {
                            return [2 /*return*/];
                        }
                        this.objectStates.callList.isInitializing = true;
                        if (!(!this.callListId || this.callList))
                            return [3 /*break*/, 6];
                        this.dashboardDataService.setCallList(this.callList);
                        if (!this.marketingStatus)
                            return [3 /*break*/, 3];
                        return [4 /*yield*/, this.callListsService.sortMembersByMarketStatus(this.callList)];
                    case 2:
                        callList = _a.sent();
                        this.setCallList(callList);
                        _a.label = 3;
                    case 3:
                        if (!(this.callList && this.callList.isTaskList))
                            return [3 /*break*/, 5];
                        return [4 /*yield*/, this.callListsService.attachContactsToCallListTasks(this.callList)];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 16];
                    case 6: return [4 /*yield*/, this.callListsService.byId(this.callListId)];
                    case 7:
                        callList = _a.sent();
                        this.dashboardDataService.setCallList(callList);
                        if (!this.marketingStatus)
                            return [3 /*break*/, 9];
                        return [4 /*yield*/, this.callListsService.sortMembersByMarketStatus(callList)];
                    case 8:
                        sortedCallList = _a.sent();
                        callList = sortedCallList;
                        _a.label = 9;
                    case 9:
                        this.setCallList(callList);
                        if (!!this.contactId)
                            return [3 /*break*/, 12];
                        groupMember = this.callList.members.find(function (member) { return !member.callResult; });
                        if (!groupMember)
                            return [3 /*break*/, 11];
                        changedContactId = this.setContactId(groupMember.contactId);
                        if (!changedContactId)
                            return [3 /*break*/, 11];
                        return [4 /*yield*/, this.loadContact()];
                    case 10:
                        _a.sent();
                        _a.label = 11;
                    case 11: return [3 /*break*/, 14];
                    case 12:
                        if (!this.contact)
                            return [3 /*break*/, 14];
                        // if we entered the page with a call list id and a contact id, but not a contact model,
                        // then the dashboard loader won't have been able to load next contacts prior to this point.
                        return [4 /*yield*/, this.dashboardDataService.loadNextContacts(this.contact)];
                    case 13:
                        // if we entered the page with a call list id and a contact id, but not a contact model,
                        // then the dashboard loader won't have been able to load next contacts prior to this point.
                        _a.sent();
                        _a.label = 14;
                    case 14:
                        if (!this.callList.isTaskList)
                            return [3 /*break*/, 16];
                        return [4 /*yield*/, this.callListsService.attachContactsToCallListTasks(this.callList)];
                    case 15:
                        _a.sent();
                        _a.label = 16;
                    case 16: return [3 /*break*/, 20];
                    case 17:
                        ex_7 = _a.sent();
                        this.logsService.exception('error while loading call lists', ex_7);
                        this.objectStates.callList.hasErrors = true;
                        throw ex_7;
                    case 18: return [4 /*yield*/, this.loadPreferences()];
                    case 19:
                        _a.sent();
                        if (this.callList) {
                            this.callList.members = this.callListsService.sortMembersForCallList(this.callList);
                        }
                        this.objectStates.callList.isInitializing = false;
                        this.metricsService.stop(this.rootMetric + ":" + this.callListMetric);
                        return [7 /*endfinally*/];
                    case 20: return [2 /*return*/];
                }
            });
        });
    };
    DashboardPage.prototype.loadPreferences = function () {
        return __awaiter(this, void 0, void 0, function () {
            var preferences, ex_8;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.preferencesService.fetch()];
                    case 1:
                        preferences = _a.sent();
                        if (preferences) {
                            this.preferences = preferences;
                            this.lastPreferencesConfig = Object.assign({}, preferences.configJson);
                        }
                        else {
                            this.preferences = new DesktopPreferencesModel();
                            this.lastPreferencesConfig = {
                                addFollowUp: null,
                                showOptions: null,
                                openTasks: null
                            };
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        ex_8 = _a.sent();
                        this.logsService.exception('error loading preferences', ex_8);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DashboardPage.prototype.loadRelatedListing = function () {
        return __awaiter(this, void 0, void 0, function () {
            var listings, ex_9;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        this.objectStates.activities.isInitializing = true;
                        if (!this.callList || !this.callList.configJson || !this.callList.configJson.listingId) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.listingsService.fetch({
                                limit: 1,
                                where: { _id: this.callList.configJson.listingId }
                            })];
                    case 1:
                        listings = _a.sent();
                        if (listings.length > 0) {
                            this.relatedListing = listings[0];
                        }
                        return [3 /*break*/, 4];
                    case 2:
                        ex_9 = _a.sent();
                        this.logsService.exception('error loading related listing', ex_9);
                        this.objectStates.activities.hasErrors = true;
                        return [3 /*break*/, 4];
                    case 3:
                        this.objectStates.activities.isInitializing = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DashboardPage.prototype.savePreferences = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.preferences.configJson.addFollowUp === this.lastPreferencesConfig.addFollowUp
                            && this.preferences.configJson.showOptions === this.lastPreferencesConfig.showOptions
                            && this.preferences.configJson.openTasks === this.lastPreferencesConfig.openTasks) {
                            return [2 /*return*/]; // preferences didn't change, nothing to update
                        }
                        return [4 /*yield*/, this.preferencesService.upsert(this.preferences)];
                    case 1:
                        _a.sent();
                        this.lastPreferencesConfig = Object.assign({}, this.preferences.configJson);
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardPage.prototype.getPropertyTypes = function () {
        return __awaiter(this, void 0, void 0, function () {
            var types;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.typesService.fetch()];
                    case 1:
                        types = _a.sent();
                        return [2 /*return*/, Object.keys(types.propertyTypes)];
                }
            });
        });
    };
    DashboardPage.prototype.logViewingTime = function (action) {
        var duration = Date.now() - this.timeOnLoad;
        var minutes = Math.floor(duration / 60000);
        var seconds = Number(((duration % 60000) / 1000).toFixed(0));
        var viewingTime = minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
        this.analyticsService.logEvent('Call Time', {
            action: action,
            viewingTime: viewingTime
        });
    };
    DashboardPage.prototype.trackLoggedCall = function (task) {
        if (task.callResult === TaskModel.RESULT.REACHED) {
            this.analyticsService.logEvent('Call Logged Reached');
        }
        else if (task.callResult === TaskModel.RESULT.LEFT_MESSAGE) {
            this.analyticsService.logEvent('Call Logged Left Message');
        }
        else if (task.callResult === TaskModel.RESULT.NOT_REACHED) {
            this.analyticsService.logEvent('Call Logged Not Reached');
        }
        else if (task.callResult === TaskModel.TYPE.EMAIL) {
            this.analyticsService.logEvent('Email Logged');
        }
        else if (task.callResult === TaskModel.TYPE.MEETING) {
            this.analyticsService.logEvent('Meeting Logged');
        }
        else if (task.callResult === TaskModel.TYPE.TOUR) {
            this.analyticsService.logEvent('Tour Logged');
        }
        else if (task.callResult === TaskModel.RESULT.OTHER) {
            this.analyticsService.logEvent('Other Logged');
        }
    };
    // Note: If at some point we are able to use ChangeDetection.OnPush, these set methods
    // can be changed to public getter/setter properties instead.
    DashboardPage.prototype.setCallList = function (callList) {
        if (this.callList !== callList) {
            this.callList = callList;
            this.loadRelatedListing();
        }
    };
    return DashboardPage;
}());
export { DashboardPage };
