<ion-content class="page list-page deals-page">
    <ion-grid class="content-grid">
        <ion-row class="list-page--header">
            <h2>Deals</h2>
        </ion-row>
        <ion-row class="list-page--body">
            <div class="list-page--body-container">
                <div class="deals-page--kanban-left-column">
                    <div class="deals-page--sort-container">
                        <apto-ionic-select [hidden]="isLoadingPursuits || isLoadingListings || isLoadingContracts || isLoadingClosedDeals"
                                           [options]="sortOptions"
                                           [value]="sortBy"
                                           (onSelectOption)="sortChanged($event.value)"></apto-ionic-select>
                    </div>
                    <div class="deals-page--pursuits deals-page--kanban-column">
                        <button *ngIf="!isLoadingPursuits"
                                class="deals-page--add-deal-button"
                                ion-button
                                clear
                                color="primary-blue"
                                (click)="addPursuit()">
                            <ion-icon name="add"
                                      material-icons
                                      small></ion-icon>
                        </button>
                        <div class="deals-page--kanban-column-header">
                            PURSUITS
                            <help-button helpText="Qualified deals you are chasing but have not yet won"></help-button>
                        </div>
                        <div class="clearfix"></div>
                        <div class="deals-page--pipeline-cards-container">
                            <busy-panel *ngIf="isLoadingPursuits"></busy-panel>
                            <pipeline-card *ngFor="let pursuit of pursuits"
                                           [pipelineObject]="pursuit"
                                           [openPipelineTasks]="whatIdToOpenTasks[pursuit._id]"
                                           [dragData]="pursuit"
                                           [dragEnabled]="pursuit.ownerId === appService.salesforceUserId  || !appService.checkOwner"
                                           dragScope="pursuit"
                                           draggable>
                            </pipeline-card>
                        </div>
                    </div>
                </div>
                <div class="deals-page--listings-and-assignments">
                    <div class="deals-page--listings-header">Listings + Assignments</div>
                    <div class="deals-page--sourcing deals-page--kanban-column">
                        <button *ngIf="!isLoadingListings"
                                class="deals-page--add-deal-button"
                                ion-button
                                clear
                                color="primary-blue"
                                (click)="addInSourcing()">
                            <ion-icon name="add"
                                      material-icons
                                      small></ion-icon>
                        </button>
                        <div class="deals-page--kanban-column-header">
                            SOURCING
                            <help-button helpText="Procuring candidate buyers, tenants or properties"></help-button>
                        </div>
                        <div class="clearfix"></div>
                        <div class="deals-page--pipeline-cards-container"
                             droppable
                             dropScope="pursuit"
                             (onDrop)="convertToSourcing($event)">
                            <busy-panel *ngIf="isLoadingListings"></busy-panel>
                            <pipeline-card *ngFor="let sourcing of sourcings"
                                           [pipelineObject]="sourcing"
                                           [callLists]="callLists"
                                           [openPipelineTasks]="whatIdToOpenTasks[sourcing._id]"
                                           [dragData]="sourcing"
                                           [dragEnabled]="sourcing._id && sourcing.ownerId === appService.salesforceUserId  || !appService.checkOwner"
                                           dragScope="sourcing"
                                           draggable>
                            </pipeline-card>
                        </div>
                    </div>
                    <div class="deals-page--negotiations deals-page--kanban-column">
                        <button *ngIf="!isLoadingListings"
                                class="deals-page--add-deal-button"
                                ion-button
                                clear
                                color="primary-blue"
                                (click)="addInNegotiation()">
                            <ion-icon name="add"
                                      material-icons
                                      small></ion-icon>
                        </button>
                        <div class="deals-page--kanban-column-header">
                            NEGOTIATIONS
                            <help-button helpText="Settlement of terms with the selected transaction partner and the client"></help-button>
                        </div>
                        <div class="clearfix"></div>
                        <div class="deals-page--pipeline-cards-container"
                             droppable
                             dropScope="sourcing"
                             (onDrop)="convertToNegotiation($event)">
                            <busy-panel *ngIf="isLoadingListings"></busy-panel>
                            <pipeline-card *ngFor="let negotiation of negotiations"
                                           [pipelineObject]="negotiation"
                                           [openPipelineTasks]="whatIdToOpenTasks[negotiation._id]"
                                           [dragData]="negotiation"
                                           [dragEnabled]="negotiation.ownerId === appService.salesforceUserId || !appService.checkOwner"
                                           dragScope="negotiation"
                                           draggable>
                            </pipeline-card>
                        </div>
                    </div>
                    <div class="deals-page--contracts deals-page--kanban-column">
                        <button *ngIf="!isLoadingContracts"
                                class="deals-page--add-deal-button"
                                ion-button
                                clear
                                color="primary-blue"
                                (click)="addInContract()">
                            <ion-icon name="add"
                                      material-icons
                                      small></ion-icon>
                        </button>
                        <div class="deals-page--kanban-column-header">
                            CONTRACTS
                            <help-button helpText="A deal in the contract period"></help-button>
                        </div>
                        <div class="clearfix"></div>
                        <div class="deals-page--pipeline-cards-container"
                             droppable
                             dropScope="negotiation"
                             (onDrop)="convertToContract($event)">
                            <busy-panel *ngIf="isLoadingContracts"></busy-panel>
                            <pipeline-card *ngFor="let contract of contracts"
                                           [pipelineObject]="contract"
                                           [openPipelineTasks]="whatIdToOpenTasks[contract._id]"
                                           [dragData]="contract"
                                           [dragEnabled]="contract.ownerId === appService.salesforceUserId || !appService.checkOwner"
                                           dragScope="contract"
                                           draggable>
                            </pipeline-card>
                        </div>
                    </div>
                </div>
                <div class="deals-page--kanban-right-column">
                    <div class="deals-page--kanban-filler"></div>
                    <div class="deals-page--closed deals-page--kanban-column">
                        <button *ngIf="!isLoadingClosedDeals"
                                class="deals-page--add-deal-button"
                                ion-button
                                clear
                                color="primary-blue"
                                (click)="addClosedDeal()">
                            <ion-icon name="add"
                                      material-icons
                                      small></ion-icon>
                        </button>
                        <div class="deals-page--kanban-column-header">
                            CLOSED
                            <help-button helpText="Successfully executed deal"></help-button>
                        </div>
                        <div class="clearfix"></div>
                        <div class="deals-page--pipeline-cards-container"
                             droppable
                             dropScope="contract"
                             (onDrop)="convertToClosed($event)">
                            <busy-panel *ngIf="isLoadingClosedDeals"></busy-panel>
                            <pipeline-card *ngFor="let closedDeal of closedDeals"
                                           [pipelineObject]="closedDeal"
                                           [openPipelineTasks]="whatIdToOpenTasks[closedDeal._id]">
                            </pipeline-card>
                        </div>
                    </div>
                </div>
            </div>
        </ion-row>
    </ion-grid>
</ion-content>