import { __extends } from "tslib";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Injector } from '@angular/core';
import { ListingModel, PropertyFilterRange } from '@apto/models';
import { PropertiesServiceBase } from './properties-service-base';
import { ImagesService } from '../images/images-service';
import { ListingsService } from '../listings/listings-service';
import { OwnershipsService } from '../ownerships/ownerships-service';
import { CompsService } from '../comps/comps-service';
import { CompaniesServiceBase } from '../companies/companies-service-base';
import { END_POINTS } from '../constants';
import { AlertController } from 'ionic-angular';
var PropertiesService = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(PropertiesService, _super);
    function PropertiesService(injector, compsService, imagesService, listingService, ownershipService, companiesServiceBase, alertController) {
        var _this = _super.call(this, injector, alertController) || this;
        _this.injector = injector;
        _this.compsService = compsService;
        _this.imagesService = imagesService;
        _this.listingService = listingService;
        _this.ownershipService = ownershipService;
        _this.companiesServiceBase = companiesServiceBase;
        _this.alertController = alertController;
        _this.autoAttachDefaultPhoto = true;
        _this.attachDefaultPhotos = function (properties, forceAttach) {
            if (forceAttach === void 0) {
                forceAttach = false;
            }
            // get the default image for each property. no need to wait for the xhr to complete.
            if (_this.autoAttachDefaultPhoto || forceAttach) {
                properties.forEach(function (property) {
                    return __awaiter(_this, void 0, void 0, function () {
                        var _a, e_1;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    if (!(property.defaultImageAttachmentId && !property.blobImageData))
                                        return [3 /*break*/, 4];
                                    _b.label = 1;
                                case 1:
                                    _b.trys.push([1, 3, , 4]);
                                    _a = property;
                                    return [4 /*yield*/, this.fetchImageData(property.defaultImageAttachmentId)];
                                case 2:
                                    _a.blobImageData = _b.sent();
                                    return [3 /*break*/, 4];
                                case 3:
                                    e_1 = _b.sent();
                                    return [3 /*break*/, 4];
                                case 4: return [2 /*return*/];
                            }
                        });
                    });
                });
            }
            return properties;
        };
        _this.attachListingsAndPhotos = function (properties) {
            _this.attachDefaultPhotos(properties);
            return _this.attachListingInfo(properties);
        };
        return _this;
    }
    PropertiesService.prototype.filterRange = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var url, errorMessage, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.configService.serviceUrl + "/" + END_POINTS.PROPERTY_FILTER_RANGES;
                        errorMessage = "Failed to get filterRange for service " + this.serviceName + ".";
                        return [4 /*yield*/, this.http.get(url)
                                .toPromise()
                                .catch(function (ex) {
                                _this.onLogException.emit({ message: errorMessage, ex: ex });
                            })];
                    case 1:
                        result = _a.sent();
                        if (result.status === 'error') {
                            throw new Error(result);
                        }
                        return [2 /*return*/, new PropertyFilterRange(result.data)];
                }
            });
        });
    };
    PropertiesService.prototype.fetchImageData = function (id) {
        return this.imagesService.byId(id);
    };
    PropertiesService.prototype.byOwner = function (contactId, removeSpaces) {
        if (removeSpaces === void 0) {
            removeSpaces = true;
        }
        return __awaiter(this, void 0, void 0, function () {
            var ownerships, propertyIds, uniquePropertyIds, properties;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.ownershipService.byContact(contactId)];
                    case 1:
                        ownerships = _a.sent();
                        propertyIds = ownerships.map(function (ownership) { return ownership.propertyId; });
                        uniquePropertyIds = Array.from(new Set(propertyIds));
                        properties = [];
                        if (!uniquePropertyIds.length)
                            return [3 /*break*/, 3];
                        return [4 /*yield*/, this.in(uniquePropertyIds)];
                    case 2:
                        properties = _a.sent();
                        _a.label = 3;
                    case 3:
                        if (removeSpaces && properties.length) {
                            properties = properties.filter(function (prop) { return !prop.parentPropertyId; });
                        }
                        return [2 /*return*/, properties];
                }
            });
        });
    };
    PropertiesService.prototype.fetch = function (params, withListingsAndPhotos) {
        if (withListingsAndPhotos === void 0) {
            withListingsAndPhotos = true;
        }
        if (!withListingsAndPhotos) {
            return _super.prototype.fetch.call(this, params);
        }
        return _super.prototype.fetch.call(this, params)
            .then(this.attachListingsAndPhotos);
    };
    PropertiesService.prototype.recent = function (params) {
        return _super.prototype.recent.call(this, params)
            .then(this.attachListingsAndPhotos);
    };
    PropertiesService.prototype.byId = function (id, cacheBust) {
        if (cacheBust === void 0) {
            cacheBust = false;
        }
        return this.in([id], cacheBust)
            .then(function (properties) { return properties.length && properties[0] || null; });
    };
    PropertiesService.prototype.in = function (ids, cacheBust) {
        if (cacheBust === void 0) {
            cacheBust = false;
        }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, _super.prototype.in.call(this, ids, cacheBust)
                        .then(this.attachListingsAndPhotos)];
            });
        });
    };
    PropertiesService.prototype.getSpaceCountForBuildingIds = function (buildingIds) {
        return __awaiter(this, void 0, void 0, function () {
            var spaces, returnMap, params, ex_1, _i, buildingIds_1, id, _a, spaces_1, space;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        spaces = [];
                        returnMap = {};
                        params = {
                            where: {
                                parentPropertyId: {
                                    $in: buildingIds
                                }
                            }
                        };
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.fetch(params, false)];
                    case 2:
                        spaces = _b.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        ex_1 = _b.sent();
                        return [3 /*break*/, 4];
                    case 4:
                        for (_i = 0, buildingIds_1 = buildingIds; _i < buildingIds_1.length; _i++) {
                            id = buildingIds_1[_i];
                            returnMap[id] = 0;
                        }
                        for (_a = 0, spaces_1 = spaces; _a < spaces_1.length; _a++) {
                            space = spaces_1[_a];
                            if (space.parentPropertyId) {
                                returnMap[space.parentPropertyId] = returnMap[space.parentPropertyId] + 1;
                            }
                        }
                        return [2 /*return*/, returnMap];
                }
            });
        });
    };
    PropertiesService.prototype.stackingPlan = function (buildingId) {
        return __awaiter(this, void 0, void 0, function () {
            var floorMap, leaseMap, spaceIds, spaces, params, ex_2, leases, tenantCompanyIds_1, tenantCompanies_1, ex_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        floorMap = {};
                        leaseMap = {};
                        spaceIds = [];
                        spaces = [];
                        params = {
                            where: {
                                parentPropertyId: buildingId
                            }
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.fetch(params, false)];
                    case 2:
                        spaces = _a.sent();
                        spaceIds = spaces.map(function (prop) { return prop._id; });
                        return [3 /*break*/, 4];
                    case 3:
                        ex_2 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4:
                        _a.trys.push([4, 9, , 10]);
                        if (!spaceIds.length)
                            return [3 /*break*/, 8];
                        return [4 /*yield*/, this.compsService.byLeasedSpaces(spaceIds)];
                    case 5:
                        leases = _a.sent();
                        tenantCompanyIds_1 = new Set();
                        leases.forEach(function (comp) {
                            if (!comp.tenantCompany && comp.tenantCompanyId) {
                                tenantCompanyIds_1.add(comp.tenantCompanyId);
                            }
                        });
                        tenantCompanies_1 = [];
                        if (!tenantCompanyIds_1.size)
                            return [3 /*break*/, 7];
                        return [4 /*yield*/, this.companiesServiceBase.in(Array.from(tenantCompanyIds_1))];
                    case 6:
                        tenantCompanies_1 = _a.sent();
                        _a.label = 7;
                    case 7:
                        leases.forEach(function (comp) {
                            if (!leaseMap[comp.spacePropertyId]) {
                                leaseMap[comp.spacePropertyId] = [];
                            }
                            // set tenant company on comp
                            if (comp.tenantCompanyId && !comp.tenantCompany) {
                                comp.tenantCompany = tenantCompanies_1.find(function (company) { return company._id === comp.tenantCompanyId; });
                            }
                            leaseMap[comp.spacePropertyId].push(comp);
                        });
                        _a.label = 8;
                    case 8: return [3 /*break*/, 10];
                    case 9:
                        ex_3 = _a.sent();
                        return [3 /*break*/, 10];
                    case 10:
                        spaces.forEach(function (space) {
                            if (!space.floor || space.floor === ' ') {
                                space.floor = '1';
                            }
                            if (!floorMap[space.floor]) {
                                floorMap[space.floor] = {
                                    totalSqft: 0,
                                    numberAvailable: 0,
                                    label: space.floor,
                                    spaces: []
                                };
                            }
                            var floor = floorMap[space.floor];
                            floor.totalSqft += space.squareFootage ? parseFloat(space.squareFootage) : 0;
                            floor.numberAvailable += space.spaceStatus === 'Available' ? 1 : 0;
                            var spaceLeases = leaseMap[space._id] ? CompsService.sortLeasesCurrentFirst(leaseMap[space._id]) : [];
                            floor.spaces.push({
                                space: space,
                                lease: spaceLeases[0] || null
                            });
                        });
                        if (!Object.keys(floorMap).length) {
                            return [2 /*return*/, null];
                        }
                        return [2 /*return*/, {
                                floors: floorMap
                            }];
                }
            });
        });
    };
    PropertiesService.prototype.attachPropertiesToComps = function (comps) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.attach('soldPropertyId', 'property', comps)];
            });
        });
    };
    PropertiesService.prototype.attachPropertiesToListings = function (listings) {
        return __awaiter(this, void 0, void 0, function () {
            var propertyIds, properties;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        propertyIds = listings.map(function (listing) { return listing.propertyId; });
                        return [4 /*yield*/, this.in(propertyIds)];
                    case 1:
                        properties = _a.sent();
                        properties.forEach(function (property) {
                            var listing = listings.find(function (l) { return l.propertyId === property._id; });
                            listing.property = property;
                        });
                        return [2 /*return*/, listings];
                }
            });
        });
    };
    PropertiesService.prototype.getOwnerships = function (propertyIds) {
        return this.ownershipService.byProperties(propertyIds);
    };
    PropertiesService.prototype.attachListingInfo = function (properties) {
        return this.listingService.forProperties(properties.map(function (property) { return property._id; }))
            .then(function (listings) {
            properties.forEach(function (property) {
                var listing = listings.find(function (checkListing) {
                    return property._id === checkListing.propertyId && checkListing.onMarketStatus === 'On-Market';
                });
                if (listing) {
                    property.forSale = !(listing.dateOffMarket || listing.onMarketStatus === ListingModel.STATUSES.IN_CONTRACT);
                    property.daysOnMarket = listing.daysOnMarket;
                    property.listingId = listing._id;
                }
            });
            return properties;
        });
    };
    return PropertiesService;
}(PropertiesServiceBase));
export { PropertiesService };
