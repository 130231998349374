<div
    *ngIf="property"
    class="prop-item selectable-item clickable"
    [ngClass]="{ clickable: onClick.observers.length > 0 }"
    [attr.data-automation]="'prop-item--' + property.name">
    <ion-checkbox
        class="small"
        *ngIf="selectable"
        [ngClass]="{ checked: selected }"
        [ngModel]="selected"
        (ngModelChange)="itemToggled($event)"
        color="primary-blue"
        mode="ios">
    </ion-checkbox>
    <ion-item
        [attr.detail-push]="onClick.observers.length > 0 ? true : null"
        [ngClass]="{ hovered: hoveredProperty && hoveredProperty._id === property._id, 'has-image': isImageShown && property.blobImageData, 'for-sale': property.forSale }"
        (mouseover)="onMouseover.emit(property)"
        (mouseout)="onMouseout.emit(property)"
        (click)="onClick.emit(property)"
        no-lines="noLines">
        <apto-icon
            icon="property"
            [circleColor]="property.forSale ? 'orange' : 'blue'"
            size="5"
            circle
            item-left></apto-icon>

        <ion-thumbnail
            *ngIf="isImageShown && property.blobImageData"
            item-right
            [ngClass]="{'prop-item--action-border': property.forSale}">
            <img [src]="property.blobImageData" alt="">
            <div
                *ngIf="property.forSale && isPhotoBannerShown"
                class="prop-item--days-on-market-label">
                    Listed {{property.daysOnMarket}} days
            </div>
        </ion-thumbnail>

        <button
                *ngIf="isRemovableFromList"
                item-right
                ion-button
                icon-only
                clear
                (click)="onRemoveFromList.emit(property)">
            <ion-icon class="close-circle"
                      circle
                      name="close"></ion-icon>
        </button>

        <h2 class="prop-item--dark-text">
            <span>{{property.name}}</span>
            <span
                *ngIf="ownership && !!ownership.contactId && (showFor === 'contact' || showFor === 'all')"
                class="link manage-related-ownership--contact-role"
                (click)="editOwnership && changeOwnerStatus($event, ownership);">
                <span *ngIf="ownership.contactRole ==='Owner' && ownership.isPrimaryContact; else ownershipRole">Primary Owner</span>
                <ng-template #ownershipRole><span>{{ownership.contactRole || '-- No status selected --'}}</span></ng-template>
            </span>
            <span
                *ngIf="ownership && !!ownership.companyId && (showFor === 'company' || showFor === 'all')"
                class="link manage-related-ownership--contact-role"
                (click)="editOwnership && changeOwnerStatus($event, ownership);">
                <span>{{ ownership.contactRole }}</span>
                <ng-template #ownershipRole>
                    <span>
                        {{ownership.contactRole || '-- No status selected --'}}
                    </span>
                </ng-template>
            </span>
        </h2>
        <p>
            {{property.addressString}}
            <span
                *ngIf="isShownOnMap && (!property.location || (property.location.lat === 0 && property.location.lng === 0))"
                class="property-mapping-error">
                - Not mappable
            </span>
        </p>

        <ul class="PropItem-dataList">
            <!--Contract specific fields-->
            <!-- <li
                *ngIf="selectedRecord && selectedRecord.contractPrice"
                data-automation="propContractPrice">{{ selectedRecord.contractPrice | currency:'USD':'symbol':'1.0-0'}}</li> -->
            <li
                *ngIf="showPrice"
                data-automation="propPrice"><strong>{{ property.salePrice | currency:'USD':'symbol':'1.0-0' }}</strong></li>
            <li
                *ngIf="showSqft"
                data-automation="propSquareFoot">{{ getSqft(property) }}</li>
            <li
                *ngIf="showPrice && showSqft"
                data-automation="propPricePerSquareFoot">{{ getPriceWithSqft(property) }}</li>
            <!--Pursuit specific fields-->
            <li
                class="prop-item--sellerPrice"
                *ngIf="selectedRecord && selectedRecord.sellerPriceExpectation"
                data-automation="propSellerPrice">{{selectedRecord.sellerPriceExpectation | currency:'USD':'symbol':'1.0-0'}}</li>
            <li
                class="prop-item--brokerPrice"
                *ngIf="selectedRecord && selectedRecord.brokerProposedPrice"
                data-automation="propBrokerPrice">
                {{selectedRecord.brokerProposedPrice | currency:'USD':'symbol':'1.0-0'}}
            </li>
            <li *ngIf="showSpaceFeature">
                <apto-button
                    *ngIf="!childSpaceCount && !isLoadingChildSpaceCounts"
                    kind="primary"
                    type="link"
                    data-automation="add-space-button"
                    (click)="addSpace($event)">
                    ADD SPACE
                </apto-button>
                <apto-button
                    *ngIf="childSpaceCount && childSpaceCount > 0 && !isLoadingChildSpaceCounts"
                    kind="primary"
                    type="link"
                    data-automation="view-spaces-button"
                    (click)="viewSpaces($event)">
                    {{childSpaceCount}} {{childSpaceCount > 1 ? 'spaces' : 'space'}}
                </apto-button>
                <apto-shimmer *ngIf="isLoadingChildSpaceCounts"></apto-shimmer>
            </li>
        </ul>
    </ion-item>
</div>
