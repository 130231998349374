var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LoadingController } from 'ionic-angular';
import { AuthService, IonicTokenService, LogsService, PlatformService } from '@apto/ionic-lib/services';
import { AppService, BackgroundCityService, AnalyticsService } from '@apto/services';
import { AppcuesService, MetricsService } from '@apto/shared';
import './app.scss';
var App = /** @class */ /*@__PURE__*/ (function () {
    function App(analyticsService, appService, appCuesService, authService, backgroundCityService, domSanitizer, loadingController, logsService, metricsService, router, platformService, tokenService) {
        var _this = this;
        this.analyticsService = analyticsService;
        this.appService = appService;
        this.appCuesService = appCuesService;
        this.authService = authService;
        this.backgroundCityService = backgroundCityService;
        this.domSanitizer = domSanitizer;
        this.loadingController = loadingController;
        this.logsService = logsService;
        this.metricsService = metricsService;
        this.router = router;
        this.platformService = platformService;
        this.tokenService = tokenService;
        this.isLoggedIn = false;
        this.setProfileAndNavigate = function (loggedInUser) {
            if (loggedInUser) {
                _this.appService.checkOwner = !loggedInUser.noDealsPermsCheck;
                _this.appService.userDisplayName = loggedInUser.firstName + ' ' + loggedInUser.lastName;
                _this.appService.profilePictureUri = _this.sanitizeDataUri(loggedInUser.profilePictureUri);
                _this.appService.salesforceUserId = loggedInUser.salesforceUserId;
                _this.isLoggedIn = true;
                if (_this.router.routerState.snapshot.url === '/login' || _this.router.routerState.snapshot.url === '/') {
                    _this.router.navigate(['/call-lists']);
                }
                _this.setupAppcues(loggedInUser);
            }
            else {
                if (_this.router.routerState.snapshot.url !== '/login') {
                    // need to preserve query params for saving off code param during login
                    _this.router.navigate(['/login'], { preserveQueryParams: true });
                }
            }
        };
        this.navFromLogout = function (hasErrors) {
            if (hasErrors === void 0) {
                hasErrors = false;
            }
            return __awaiter(_this, void 0, void 0, function () {
                var params, ex_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!this.platformService.isRunningNative) {
                                // TODO: verify we still need this
                                window.onbeforeunload = null;
                            }
                            AppService.onLogout.emit();
                            this.isLoggedIn = false;
                            this.analyticsService.reset();
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, this.tokenService.clearToken()];
                        case 2:
                            _a.sent();
                            params = hasErrors ? { hasErrors: hasErrors } : {};
                            this.router.navigate(["/login"], { queryParams: params });
                            return [3 /*break*/, 4];
                        case 3:
                            ex_1 = _a.sent();
                            this.logsService.exception('Failed to clear cache on logout', ex_1);
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        };
        this.authService.loginNavHandler = this.setProfileAndNavigate;
        this.authService.logoutNavHandler = this.navFromLogout;
        this.setupMetrics();
        this.checkLogin();
    }
    App.prototype.checkLogin = function () {
        return __awaiter(this, void 0, void 0, function () {
            var loader, ex_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loader = this.loadingController.create({
                            content: 'Checking your login status',
                            cssClass: 'app-loading',
                            showBackdrop: false
                        });
                        loader.present();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.authService.checkLoginAndNavigate()];
                    case 2:
                        _a.sent();
                        // the user may still be logged in, but with an old token. set their new token to ensure
                        // that the api doesn't have to constantly use the refresh token to update a cached expired token
                        if (this.authService.user) {
                            this.tokenService.setToken(this.authService.user.token);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        ex_2 = _a.sent();
                        this.navFromLogout(true);
                        return [3 /*break*/, 4];
                    case 4:
                        loader.dismiss();
                        return [2 /*return*/];
                }
            });
        });
    };
    App.prototype.sanitizeDataUri = function (uri) {
        if (!uri) {
            this.appService.profilePictureUri = null;
            return;
        }
        if (uri && uri.startsWith('data:')) {
            return this.domSanitizer.bypassSecurityTrustUrl(uri);
        }
        return uri;
    };
    App.prototype.setupAppcues = function (user) {
        if (!this.isLoggedIn) {
            return;
        }
        this.appCuesService.identify(user.salesforceUserId, {
            name: user.firstName + ' ' + user.lastName,
            email: user.email || null,
            username: user.userId || null
        });
    };
    App.prototype.setupMetrics = function () {
        var _this = this;
        this.authService.onSetUser.subscribe(function (user) {
            if (user) {
                _this.metricsService.setUser(user);
            }
        });
    };
    return App;
}());
export { App };
