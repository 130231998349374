import { PipeTransform } from '@angular/core';
var PricePerSqFtFormat = /** @class */ /*@__PURE__*/ (function () {
    function PricePerSqFtFormat() {
    }
    PricePerSqFtFormat.prototype.transform = function (value) {
        if (isNaN(value)) {
            return value;
        }
        else {
            var localizedValue = !value || !isFinite(value)
                ? 0
                : value.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 });
            return "$" + localizedValue + "/sqft";
        }
    };
    return PricePerSqFtFormat;
}());
export { PricePerSqFtFormat };
